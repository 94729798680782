import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Avaleht from "./pages/Webpage1Avaleht";
import Webpage3Cookie_note from "./pages/Webpage3Cookie_note";
import Webpage4Page_not_found from "./pages/Webpage4Page_not_found";
import Webpage9Men_____vaated from "./pages/Webpage9Men_____vaated";
import Webpage11Menu_templates from "./pages/Webpage11Menu_templates";
import Webpage13Home from "./pages/Webpage13Home";

var baseURL = 'https://lilleorumtu.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiOWFhNzNhOGQxZWQzNmM0NzI3NjJiNzgwYWZjN2U5ZjVlN2FmNjk2Y2I1NzIxMjE2YWNjN2U2MDExM2U5ZTc5MDM3ZDdkM2UxY2VmZjA5NTIiLCJpYXQiOjE3MjQyNzA0NjYuOTI2MTk2LCJuYmYiOjE3MjQyNzA0NjYuOTI2MjA2LCJleHAiOjE3MjQzNTY4NjYuOTE5MjE0LCJzdWIiOiI2NyIsInNjb3BlcyI6W119.GYx9ZaXA50XVKa1dlKgeOdC3SCmBh-gHWKyBS9oyQgm23xzI_TtX4xxh4OcTc-1V0BH6b1JZkJ15m6tP37q3-wfHMu0TgWnkJGre1BduckllRK-8ZQpF7wqrn1vSo0vww1jB_Yix2RLq95pFc7O-KUmJUYSermN87GbSnsEXIOWURP7wECiv3CFJ3A5-Xg7ntRJOxTYnJtjUsgqG6QPq9NTG5WeUTLMGcQnUez9LOlmDcTeXluXu5_ejHcxbSOpzY29YzAWTx--sADI3QPIJzFGugy2zzlLMH4uwHm_sY1pThz_o5XV7fZch0LgBBN2-DvPBKDqsyMWFV8F9kuHTl8XXZEFUxO6dDUUDqlzSkm8BrUZZpF0j0n8bEGKOPcMjEAJEMXFYbLJiFqYUq8UyxcyI7rAYLgQSojIqmfcSBUDNnim7YBV-KCYROlYuR47y_Y6vxgnNTrM7n0RFzTKDmr3eE34mzd1IsL9f4UwFZiz-dt0LyNGVaGOg35ZCxFo0Un0vKyPLfA5J9-V9ip3oqMOYg7iPuMRlSF4e6UMgDH4-NPBV1RgL4-zhZAvzvr1qOPcKTOGmKeXOLhgB0kHalJ_uRykMelpNPF6jDqtJzyeDu5wmgZZTc_gXWGBzB5xSb6zVqYbayu1QGGtvAq-S2ABbAidU5cbMIzmELiWzIHE',
    'Access-api-key': "eyJpdiI6Ik1sMnltQXBIZUNpclJsY0xXSGsvTVE9PSIsInZhbHVlIjoibXRCdU42RFJjYXJ5QlduY2hZRHp4Zz09IiwibWFjIjoiY2RhZjI0M2U2NjBhMTM1YjBlYjliNzBkMWQwMWU2Y2E1OTViZmI0NTc1MzY2NTcyYTA3ZDcyOWYyMDg5OGZmZiIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6Ik5HVWRjWnRwVXZaWFBUZUlOUE5GU2c9PSIsInZhbHVlIjoiWEdSVFRXeUxxWjJLYUxmYUhZcHcwZz09IiwibWFjIjoiYWEyY2RkMDM4NTdjMWRhZWNiMDA0ZWMxZDBjNjYzYWUzM2ZkYTVkYzcyNWZjOWRmODEwNmU3NTliNzc3NzM5ZSIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-WW9QW6"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage1Avaleht />} exact />;
                                                                    <Route path="/en/cookie-note" element={<Webpage3Cookie_note />} exact />;
                                                                    <Route path="/en/*" element={<Webpage4Page_not_found />} exact />;
                                                <Route path="*" element={<Webpage4Page_not_found />} exact />;
                                                                    <Route path="/et/templates" element={<Webpage9Men_____vaated />} exact />;
                                                                    <Route path="/en/templates" element={<Webpage11Menu_templates />} exact />;
                                                                    <Route path="/en/" element={<Webpage13Home />} exact />;
                                                                
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}