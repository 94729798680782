import { useRef } from "react";
import { useAppSelector } from "../../../store/hooks";
import {
  useShoppingCart,
  LocationProduct,
} from "../context/ShoppingCartContext";
import usePageLanguage from "../hooks/usePageLanguage";
import { pf, getPageLocaleUrl } from "../utilities/helpers";
import useLazyLoad from "../utilities/useLazyLoad";
import React from "react";

interface Props {
  locationProduct: LocationProduct;
}

const languageTranslations = {
  et: {
    add_to_cart: "LISA OSTUKORVI",
    view_product: "VAATA TOODET",
  },
  en: {
    add_to_cart: "ADD TO CART",
    view_product: "VIEW PRODUCT",
  },
};

export default function ProductComponent({ locationProduct }: Props) {
  const { increaseCartQuantity, locationProducts } = useShoppingCart();
  const lang = usePageLanguage();
  const productItemRef = useRef<HTMLDivElement>(null);
  const pages = useAppSelector((state) => state.builder.pages.list);
  const loaded = useLazyLoad(productItemRef, locationProduct.firstFileUrl);
  const locale = usePageLanguage();
  const languages = useAppSelector(
    (state) => state.builder.languageSettings.activeLanguages
  );
  const productPage = pages.find(
    (page) => page.page_type === "product" && page.language.locale === lang
  );
  var languageProductHref = productPage
    ? getPageLocaleUrl(productPage, languages).replace(/:id$/, "")
    : "/et/toode/";
  if (languageProductHref.substring(0, 1) !== "/") {
    languageProductHref = "/" + languageProductHref;
  }

  const getPriceRange = () => {
    if (locationProduct.product.variation_products.length === 0) return null;

    // Find the lowest price and highest price but keep everything else as well in the object.

    const lowestPrice = locationProduct.product.variation_products.sort(
      (a: any, b: any) => a.price - b.price
    )[0];

    const highestPrice = locationProduct.product.variation_products.sort(
      (a: any, b: any) => b.price - a.price
    )[0];

    const lowestPriceProduct = locationProducts.find(
      (product) => product.id === lowestPrice.product_id
    );
    const highestPriceProduct = locationProducts.find(
      (product) => product.id === highestPrice.product_id
    );

    let minPrice =
      lowestPriceProduct && lowestPriceProduct.price_with_discounts
        ? lowestPriceProduct.price_with_discounts
        : lowestPrice.price_total;

    let maxPrice =
      highestPriceProduct && highestPriceProduct.price_with_discounts
        ? highestPriceProduct.price_with_discounts
        : highestPrice.price_total;

    return { minPrice, maxPrice };
  };

  return (
    <div className="productcol">
      <div className="web-product" ref={productItemRef}>
        <a
          href={`${languageProductHref}${locationProduct.id}`}
          className="product-img"
          style={{
            backgroundImage:
              locationProduct.product.variation_products.length > 0 &&
              locationProduct.product.product_files.length <= 0
                ? (() => {
                    // Find the first variation product with a valid product_files.url
                    const variationProductWithUrl =
                      locationProduct.product.variation_products.find(
                        (variation) => {
                          const product = locationProducts.find(
                            (x) => x.id === variation.product_id
                          );

                          return (
                            product &&
                            product.product &&
                            product.product.product_files.some(
                              (file) => file.url
                            )
                          );
                        }
                      );

                    // Get the URL from the found variation product
                    const url = variationProductWithUrl
                      ? locationProducts.find(
                          (x) => x.id === variationProductWithUrl.product_id
                        )?.product.product_files[0]?.url
                      : "none";

                    return `url(${url})`;
                  })()
                : loaded
                ? loaded.map((src) => `url("${src}")`).join(", ")
                : "none",

            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          <img
            src={
              locationProduct.product.variation_products.length > 0 &&
              locationProduct.product.product_files.length <= 0
                ? locationProducts.find(
                    (x) =>
                      x.id ===
                      locationProduct.product?.variation_products[0]?.product_id
                  )?.product.product_files[0]?.url
                : loaded
                ? loaded.map((src) => `url("${src}")`).join(", ")
                : "none"
            }
            alt={locationProduct?.translations?.[lang].name + " pilt."}
            style={{ width: "100%" }}
          ></img>
          <div className="product-info-overlay">
            <div
              dangerouslySetInnerHTML={{
                __html: locationProduct?.translations?.[lang].shortDescription,
              }}
            />
          </div>
        </a>
        <div className="padding-10 pb-20">
          <div className="product-name">
            <a href={`${languageProductHref}${locationProduct.id}`}>
              {locationProduct?.translations?.[lang].name}&nbsp;
            </a>
          </div>
          <div className="productPrice">
            {(!locationProduct.product.variations ||
              locationProduct.product.variations.length === 0) && (
              <>
                <span
                  style={
                    locationProduct.adjustments &&
                    locationProduct.adjustments?.length > 0
                      ? { textDecoration: "line-through" }
                      : {}
                  }
                >
                  {pf(locationProduct.price_total)}&euro;
                </span>

                {locationProduct.price_with_discounts &&
                locationProduct.adjustments?.length > 0 ? (
                  <span className="discount-price-color">
                    {pf(Number(locationProduct.price_with_discounts))}&euro;
                  </span>
                ) : (
                  ""
                )}
              </>
            )}

            {locationProduct.product.variations &&
              locationProduct.product.variations.length > 0 && (
                <span>
                  {getPriceRange()?.minPrice !== getPriceRange()?.maxPrice && (
                    <>
                      {pf(getPriceRange()?.minPrice || 0)} &euro; -{" "}
                      {pf(getPriceRange()?.maxPrice || 0)} &euro;
                    </>
                  )}
                  {getPriceRange()?.minPrice === getPriceRange()?.maxPrice && (
                    <>{pf(getPriceRange()?.minPrice || 0)} &euro;</>
                  )}
                </span>
              )}
          </div>
          <div>
            <button
              className="btn btn-primary addProductToCart shop-controlled-border-radius"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                if (
                  !locationProduct.product.variations ||
                  locationProduct.product.variations.length === 0
                ) {
                  increaseCartQuantity(locationProduct.id);
                } else if (locationProduct.product.variations.length > 0) {
                  window.location.href = `${languageProductHref}${locationProduct.id}`;
                }
              }}
            >
              {!locationProduct.product.variations ||
              locationProduct.product.variations.length === 0
                ? languageTranslations[locale]?.add_to_cart
                : languageTranslations[locale]?.view_product}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
