import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import CategoryComponent from "./CategoryComponent";
import { ProductCategory } from "../context/ShoppingCartContext";
import { useAppSelector } from "../../../store/hooks";
import usePageLanguage from "../hooks/usePageLanguage";
import React from "react";

interface Props {
  productCategoryId: number | null;
  setProductCategoryId: (val: number) => void;
  depthLevel?: number;
}

declare var API: any;

const languageTranslations = {
  et: {
    products: "Tooted",
  },
  en: {
    products: "Products",
  },
};

export default function CategoriesComponent({
  productCategoryId,
  setProductCategoryId,
  depthLevel = 0,
}: Props) {
  const location = useLocation();
  const locale = usePageLanguage();
  const colors = useAppSelector((state) => state.builder.colors);
  var themeColor: any = null;
  const [productCategories, setProductCategories] = useState<ProductCategory[]>(
    []
  );

  useEffect(() => {
    if (location.search.includes("?category=")) {
      setProductCategoryId(Number(location.search.split("?category=")[1]));
    }
  }, []);

  if (colors) {
    themeColor = colors.filter((x) => x.name === "SHOPTHEMECOLOR")[0]?.color;
  }

  useEffect(() => {
    if (typeof API !== "undefined") {
      window
        // @ts-ignore
        .axios("/loadProductCategories.json", {
          method: "GET",
          cache: "no-cache",
          headers: { "Content-Type": "application/json" },
        })
        .then((res: any) => {
          setProductCategories(res.data);
        });
    } else {
      fetch("/webstoreApi/loadProductCategories.json", {
        method: "GET",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((res) => {
          setProductCategories(res);
        });
    }
  }, []);

  return (
    <div key={"categories"} className="panel categories margin-top-15">
      <div className="panel-heading">
        <h5 style={{ color: themeColor }}>
          <a
            href={undefined}
            className="d-block d-md-none cats-title"
            id="cats-title-m"
            data-toggle="collapse"
            style={{ color: themeColor }}
          >
            {languageTranslations[locale]?.products}
          </a>
          <a href={undefined} className="d-none d-md-block cats-title">
            {languageTranslations[locale]?.products}
          </a>
        </h5>
      </div>
      <div className="panel-collapse collapse in show" id="categories">
        <ul>
          {productCategories!.map((productCategory) => {
            return (
              <CategoryComponent
                key={productCategory.id}
                depthLevel={depthLevel}
                productCategories={productCategories}
                productCategory={productCategory}
                productCategoryId={productCategoryId}
                setProductCategoryId={setProductCategoryId}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
}
